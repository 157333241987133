<template>
  <div class="iss-main contactFrequency">
    <div class="title">联系频次</div>
    <div class="titleTwo">设定一个频次来保持联系并建立更牢固的关系！</div>

    <div class="pinci">
      <a-form-item class="relation">
        <!--    默认选中所有联系人   -->
        <a-tree-select
          v-model:value="form.groupId"
          placeholder="请选择联系人"
          allow-clear
          :tree-data="treeData"
          :replace-fields="{
            key: 'id',
            value: 'id',
            title: 'groupName',
            children: 'children',
          }"
          @change="handleChange()"
        />
      </a-form-item>
      <!--   批量修改联系人频次 mode="multiple" -->
      <div class="dropDown" ref="selectBox">
        <div class="itemVal" @click="handleVal">
          <!--       icon   -->
          <img
            src="@/assets/images/reminder/icons.png"
            alt=""
            class="iconsImg"
          />
          {{ itemVal }}
        </div>

        <div class="dropDownList" v-show="showList">
          <div
            v-for="item in earlyList"
            :key="item"
            class="dropDownItem"
            @click="handleDropDownItem(item)"
          >
            {{ item.itemNameCn }}
          </div>
        </div>
      </div>
    </div>

    <a-spin :spinning="spinning">
      <div class="draggableContent">
        <!--  不要保持联系 -->
        <!--        <div class="arrayListNever1">-->
        <!--          <div class="eleText">不要保持联系</div>-->
        <!--          &lt;!&ndash;       chosenClass="chosen"    选定项目的类名-->
        <!--          dragClass: "sortable-drag",  // 拖动项目的类名-->
        <!-- chosenClass="draggable"-->
        <!--            dragClass= "ele"-->
        <!-- &ndash;&gt;-->
        <!--          <draggable-->
        <!--            :list="list.arrayListNever"-->
        <!--            ghost-class="ghost"-->
        <!--            :disabled="isDrag"-->
        <!--            chosen-class="chosenClass"-->
        <!--            animation="300"-->
        <!--            @start="onStart('arrayListNever')"-->
        <!--            @end="onEnd('box1')"-->
        <!--            @change="change"-->
        <!--            class="draggable itemAll"-->
        <!--            group="people"-->
        <!--            :options="box1DragOptions"-->
        <!--            :sort="false"-->
        <!--            :class="{-->
        <!--              showBorder: type !== 'arrayListNever' && isShow,-->
        <!--              arrayListNever: obj.arrayListNever,-->
        <!--            }"-->
        <!--          >-->
        <!--            <template #item="{ element }">-->
        <!--              <div class="ele">-->
        <!--                <div class="content">-->
        <!--                  &lt;!&ndash;              头像   &ndash;&gt;-->
        <!--                  <div class="header">-->
        <!--                    <a-avatar-->
        <!--                      v-if="element.avatar"-->
        <!--                      :src="setAvatar(element.avatar)"-->
        <!--                      @click="handleGoToContact(element)"-->
        <!--                      class="headerUrl"-->
        <!--                    />-->
        <!--                    <div-->
        <!--                      v-else-->
        <!--                      @click="handleGoToContact(element)"-->
        <!--                      class="headerText"-->
        <!--                    >-->
        <!--                      <div v-if="element.name">-->
        <!--                        {{ element.name[0] }}-->
        <!--                      </div>-->
        <!--                    </div>-->
        <!--                  </div>-->

        <!--                  <div class="name">-->
        <!--                    {{ element.name }}-->
        <!--                  </div>-->
        <!--                </div>-->
        <!--              </div>-->
        <!--            </template>-->
        <!--          </draggable>-->
        <!--          <div class="eleContentNone" v-if="list.arrayListNever.length === 0">-->
        <!--            <div>没有与此频次匹配的联系人</div>-->
        <!--          </div>-->
        <!--        </div>-->

        <!--        &lt;!&ndash;  无  &ndash;&gt;-->
        <!--        <div class="arrayListNot2">-->
        <!--          <div class="eleText">无</div>-->
        <!--          <draggable-->
        <!--            :list="list.arrayListNot"-->
        <!--            ghost-class="ghost"-->
        <!--            :disabled="isDrag"-->
        <!--            chosen-class="chosenClass"-->
        <!--            animation="300"-->
        <!--            @start="onStart('arrayListNot')"-->
        <!--            @end="onEnd('box2')"-->
        <!--            @change="change"-->
        <!--            class="draggable itemAll"-->
        <!--            itemKey="name"-->
        <!--            group="people"-->
        <!--            :sort="false"-->
        <!--            :options="dragOptions"-->
        <!--            :class="{-->
        <!--              showBorder: type !== 'arrayListNot' && isShow,-->
        <!--              arrayListNot: obj.arrayListNot,-->
        <!--            }"-->
        <!--          >-->
        <!--            <template #item="{ element }">-->
        <!--              <div class="ele">-->
        <!--                <div class="content">-->
        <!--                  &lt;!&ndash;              头像   &ndash;&gt;-->
        <!--                  <div class="header">-->
        <!--                    <a-avatar-->
        <!--                      v-if="element.avatar"-->
        <!--                      :src="setAvatar(element.avatar)"-->
        <!--                      @click="handleGoToContact(element)"-->
        <!--                      class="headerUrl"-->
        <!--                    />-->
        <!--                    <div-->
        <!--                      v-else-->
        <!--                      @click="handleGoToContact(element)"-->
        <!--                      class="headerText"-->
        <!--                    >-->
        <!--                      <div v-if="element.name">-->
        <!--                        {{ element.name[0] }}-->
        <!--                      </div>-->
        <!--                    </div>-->
        <!--                  </div>-->

        <!--                  <div class="name">-->
        <!--                    {{ element.name }}-->
        <!--                  </div>-->
        <!--                </div>-->
        <!--              </div>-->
        <!--            </template>-->
        <!--          </draggable>-->
        <!--          <div class="eleContentNone" v-if="list.arrayListNot.length === 0">-->
        <!--            <div>没有与此频次匹配的联系人</div>-->
        <!--          </div>-->
        <!--        </div>-->
        <!--    只要group相同，就可以互相拖拽-->
        <div class="arrayListWeek3">
          <div class="eleText">每周</div>
          <draggable
            :list="list.arrayListWeek"
            ghost-class="ghost"
            :disabled="isDrag"
            chosen-class="chosenClass"
            animation="300"
            @start="onStart('arrayListWeek')"
            @end="onEnd('box3')"
            @change="change"
            class="draggable itemAll"
            group="people"
            :sort="false"
            :options="dragOptions"
            :class="{
              showBorder: type !== 'arrayListWeek' && isShow,
              arrayListWeek: obj.arrayListWeek,
            }"
          >
            <template #item="{ element }">
              <div class="ele">
                <div class="content">
                  <!--              头像   -->
                  <div class="header">
                    <a-avatar
                      v-if="element.avatar"
                      :src="setAvatar(element.avatar)"
                      @click="handleGoToContact(element)"
                      class="headerUrl"
                    />
                    <div
                      v-else
                      @click="handleGoToContact(element)"
                      class="headerText"
                    >
                      <div v-if="element.name">
                        {{ element.name[0] }}
                      </div>
                    </div>
                  </div>

                  <div class="name">
                    {{ element.name }}
                  </div>
                </div>
              </div>
            </template>
          </draggable>
          <div v-if="list.arrayListWeek.length === 0" class="eleContentNone">
            <div>没有与此频次匹配的联系人</div>
          </div>
        </div>

        <div class="arrayListMonth4">
          <div class="eleText">每月</div>
          <draggable
            :list="list.arrayListMonth"
            ghost-class="ghost"
            :disabled="isDrag"
            chosen-class="chosenClass"
            animation="300"
            @start="onStart('arrayListMonth')"
            @end="onEnd('box4')"
            @change="change"
            class="draggable itemAll"
            group="people"
            :sort="false"
            :class="{
              showBorder: type !== 'arrayListMonth' && isShow,
              arrayListMonth: obj.arrayListMonth,
            }"
          >
            <template #item="{ element }">
              <div class="ele">
                <div class="content">
                  <!--              头像   -->
                  <div class="header">
                    <a-avatar
                      v-if="element.avatar"
                      :src="setAvatar(element.avatar)"
                      @click="handleGoToContact(element)"
                      class="headerUrl"
                    />
                    <div
                      v-else
                      @click="handleGoToContact(element)"
                      class="headerText"
                    >
                      <div v-if="element.name">
                        {{ element.name[0] }}
                      </div>
                    </div>
                  </div>

                  <div class="name">
                    {{ element.name }}
                  </div>
                </div>
              </div>
            </template>
          </draggable>
          <div v-if="list.arrayListMonth.length === 0" class="eleContentNone">
            <div>没有与此频次匹配的联系人</div>
          </div>
        </div>

        <div class="arrayListQuarterly5">
          <div class="eleText">每季度</div>
          <draggable
            :list="list.arrayListQuarterly"
            ghost-class="ghost"
            :disabled="isDrag"
            chosen-class="chosenClass"
            animation="300"
            @start="onStart('arrayListQuarterly')"
            @end="onEnd('box5')"
            @change="change"
            class="draggable itemAll"
            group="people"
            :sort="false"
            :class="{
              showBorder: type !== 'arrayListQuarterly' && isShow,
              arrayListQuarterly: obj.arrayListQuarterly,
            }"
          >
            <template #item="{ element }">
              <div class="ele">
                <div class="content">
                  <!--              头像   -->
                  <div class="header">
                    <a-avatar
                      v-if="element.avatar"
                      :src="setAvatar(element.avatar)"
                      @click="handleGoToContact(element)"
                      class="headerUrl"
                    />
                    <div
                      v-else
                      @click="handleGoToContact(element)"
                      class="headerText"
                    >
                      <div v-if="element.name">
                        {{ element.name[0] }}
                      </div>
                    </div>
                  </div>

                  <div class="name">
                    {{ element.name }}
                  </div>
                </div>
              </div>
            </template>
          </draggable>
          <div
            v-if="list.arrayListQuarterly.length === 0"
            class="eleContentNone"
          >
            <div>没有与此频次匹配的联系人</div>
          </div>
        </div>

        <div class="arrayListSemiyearly6">
          <div class="eleText">每半年</div>
          <draggable
            :list="list.arrayListSemiyearly"
            ghost-class="ghost"
            :disabled="isDrag"
            chosen-class="chosenClass"
            animation="300"
            @start="onStart('arrayListSemiyearly')"
            @end="onEnd('box6')"
            @change="change"
            class="draggable itemAll"
            group="people"
            :sort="false"
            :class="{
              showBorder: type !== 'arrayListSemiyearly' && isShow,
              arrayListSemiyearly: obj.arrayListSemiyearly,
            }"
          >
            <template #item="{ element }">
              <div class="ele">
                <div class="content">
                  <!--              头像   -->
                  <div class="header">
                    <a-avatar
                      v-if="element.avatar"
                      :src="setAvatar(element.avatar)"
                      @click="handleGoToContact(element)"
                      class="headerUrl"
                    />
                    <div
                      v-else
                      @click="handleGoToContact(element)"
                      class="headerText"
                    >
                      <div v-if="element.name">
                        {{ element.name[0] }}
                      </div>
                    </div>
                  </div>

                  <div class="name">
                    {{ element.name }}
                  </div>
                </div>
              </div>
            </template>
          </draggable>
          <div
            v-if="list.arrayListSemiyearly.length === 0"
            class="eleContentNone"
          >
            <div>没有与此频次匹配的联系人</div>
          </div>
        </div>

        <div class="arrayListYear7">
          <div class="eleText">每年</div>
          <draggable
            :list="list.arrayListYear"
            ghost-class="ghost"
            :disabled="isDrag"
            chosen-class="chosenClass"
            animation="300"
            @start="onStart('arrayListYear')"
            @end="onEnd('box7')"
            @change="change"
            class="draggable itemAll"
            group="people"
            :sort="false"
            :class="{
              showBorder: type !== 'arrayListYear' && isShow,
              arrayListYear: obj.arrayListYear,
            }"
          >
            <template #item="{ element }">
              <div class="ele">
                <div class="content">
                  <!--              头像   -->
                  <div class="header">
                    <a-avatar
                      v-if="element.avatar"
                      :src="setAvatar(element.avatar)"
                      @click="handleGoToContact(element)"
                      class="headerUrl"
                    />
                    <div
                      v-else
                      @click="handleGoToContact(element)"
                      class="headerText"
                    >
                      <div v-if="element.name">
                        {{ element.name[0] }}
                      </div>
                    </div>
                  </div>

                  <div class="name">
                    {{ element.name }}
                  </div>
                </div>
              </div>
            </template>
          </draggable>
          <div v-if="list.arrayListYear.length === 0" class="eleContentNone">
            <div>没有与此频次匹配的联系人</div>
          </div>
        </div>
      </div>
    </a-spin>
  </div>
</template>

<script>
import draggable from 'vuedraggable';
import { Form, message, TreeSelect, Avatar, Spin } from 'ant-design-vue';
import { nextTick, onMounted, reactive, toRaw, ref, toRefs } from 'vue';
import contactAll from '@/api/contactAll';
import { useStore } from 'vuex';
import frequencyApi from '@/api/frequency';
import { setAvatar } from '@/utils';
import { useRouter } from 'vue-router';
import { local } from '@/utils/storage';

export default {
  components: {
    draggable,
    AFormItem: Form.Item,
    // ASelect: Select,
    // ASelectOption: Select.Option,
    ATreeSelect: TreeSelect,
    AAvatar: Avatar,
    ASpin: Spin,
  },
  setup() {
    const store = useStore();
    const router = useRouter();
    const selectBox = ref();
    const state = reactive({
      userId: store.state.account.user.id,
      interval: '',
      treeData: [],
      isShow: false,
      spinning: false,
      isBulr: false,
      endClass: '',
      isDrag: false,
      // state.endClass = key
      obj: {
        arrayListMonth: false,
        arrayListNever: false,
        arrayListNot: false,
        arrayListQuarterly: false,
        arrayListSemiyearly: false,
        arrayListWeek: false,
        arrayListYear: false,
      },
      type: '',
      list: {
        arrayListMonth: [],
        arrayListNever: [],
        arrayListNot: [],
        arrayListQuarterly: [],
        arrayListSemiyearly: [],
        arrayListWeek: [],
        arrayListYear: [],
      },
      frequenctList: [],
      earlyList: [],
      itemVal: '',
      dragOptions: {
        group: 'items',
        disabled: false,
        ghostClass: 'drag-ghost',
        chosenClass: 'drag-chosen',
        forceFallback: true,
        direction: 'horizontal', // 设置为水平方向
      },
      box1DragOptions: {
        group: 'items',
        disabled: false,
        ghostClass: 'drag-ghost',
        chosenClass: 'drag-chosen',
        forceFallback: true,
        direction: 'horizontal', // 设置为水平方向
      },
      showList: false,
    });
    const form = reactive({
      groupId: '1',
      userId: store.state.account.user.id,
    });

    contactAll.getTree('', { code: 'FREQUENCY' }).then(res => {
      if (res) {
        state.frequenctList = res;
      }
    });

    // 更新拖拽列表
    const getUserInfo = parmas => {
      contactAll.updateUserInfo('', parmas).then(res => {
        if (res) {
          message.success('拖拽成功');
          // 频次列表接口
          queryGroups();
        }
      });
    };

    // 查询拖拽列表
    const queryGroups = () => {
      let parmas = {
        ...toRaw(form),
        groupId: form.groupId === '1' ? '' : form.groupId,
      };

      frequencyApi.queryGroups('', parmas).then(res => {
        if (res) {
          local.set('queryGroupsList', res);
          state.list = res;
          setTimeout(() => {
            state.spinning = false;
          }, 1000);
        }
      });
    };

    onMounted(() => {
      queryGroups();

      document.addEventListener('click', e => {
        if (selectBox.value) {
          if (!selectBox.value.contains(e.target)) {
            state.showList = false;
            state.loading = false;
          }
        }
      });

      // 提前提醒时间
      contactAll.getTree('', { code: 'FREQUENCY_ADVANCE_TYPE' }).then(res => {
        if (res) {
          state.earlyList = res;

          // 查询当前用户通知
          frequencyApi.getUserNotifySettingList('', state.userId).then(res => {
            if (res) {
              // 默认提前两天提醒
              if (res.daysBeforeReminder) {
                let newList = [];
                let toStr = res.daysBeforeReminder.toString();
                state.earlyList.forEach(item => {
                  if (item.itemCode === toStr) {
                    return newList.push(item);
                  }
                });
                state.itemVal = newList[0].itemNameEn;
              }
            }
          });
        }
      });
    });

    // 查询全部分组带子级
    frequencyApi.groupsId('', state.userId).then(res => {
      if (res) {
        state.treeData = res;
      }
    });

    //拖拽开始的事件
    const onStart = type => {
      console.log('开始拖拽', type);
      // let data = local.get('queryGroupsList');
      // console.log('data', data);
      state.isShow = true;
      state.type = type;
      // 遍历obj, 拿到key, 判断key 跟 type 是否一致，一致就给type 就不加边框
      //  获取data 跟当前 拖动的数组对比， 当前数据比原有数组长度大，  class 添加边框，
    };
    // todo 拖拽结束的事件
    const onEnd = box => {
      console.log('结束拖拽', box);
      state.isShow = false;
      // 取消拖动时添加的class
      state.obj[state.endClass] = false;
      clearInterval(state.interval);
    };

    return {
      ...toRefs(state),
      form,
      onStart,
      onEnd,
      getUserInfo,
      queryGroups,
      setAvatar,
      selectBox,
      handleGoToContact(element) {
        router.push(`/contacts/${element.id}`);
      },
      handleChange() {
        // 调取频次列表接口
        state.spinning = true;
        queryGroups();
      },
      //move回调方法
      // onMove(evt) {
      //   let chosenElements = document.querySelectorAll('.itemAll');
      //   // 遍历元素并修改样式
      //   chosenElements.forEach(element => {
      //     element.style.border = 'none';
      //   });
      //   let id = evt.to.classList[evt.to.classList.length - 1];
      //   let parentElement = document.getElementsByClassName(id)[0];
      //   parentElement.style.border = '1px dashed #5979f8';
      // },

      change(evt) {
        console.log('evt', evt.added.element);
        let { id, userId } = evt.added.element;
        let data = local.get('queryGroupsList');
        // 原有数据跟现在数据数组长度的对比, 长度增加的,就表示增加了元素
        let art = '';
        // for (const key in data) {
        // console.log('key', key, data[key])
        for (const k in state.list) {
          // 不再联系
          if (k === 'arrayListNever') {
            if (state.list[k].length > data[k].length) {
              art = '60';
            }
          }
          // 月
          if (k === 'arrayListMonth') {
            if (state.list[k].length > data[k].length) {
              art = '20';
            }
          }
          // 无
          if (k === 'arrayListNot') {
            if (state.list[k].length > data[k].length) {
              art = '80';
            }
          }
          // 周
          if (k === 'arrayListWeek') {
            if (state.list[k].length > data[k].length) {
              art = '10';
            }
          }
          // 每季度
          if (k === 'arrayListQuarterly') {
            if (state.list[k].length > data[k].length) {
              art = '30';
            }
          }
          // 每半年
          if (k === 'arrayListSemiyearly') {
            if (state.list[k].length > data[k].length) {
              art = '40';
            }
          }
          // 每年
          if (k === 'arrayListYear') {
            if (state.list[k].length > data[k].length) {
              art = '50';
            }
          }
        }

        // }
        // console.log('srt', art)

        // todo 调取接口更新频次
        let parmas = {
          id: [id],
          userId: userId,
          frequency: art,
        };
        getUserInfo(parmas);
      },

      handleVal() {
        state.showList = !state.showList;
      },
      handleDropDownItem(item) {
        state.loading = true;
        let parmas = {
          userId: store.state.account.user.id,
          daysBeforeReminder: item.itemCode,
        };
        frequencyApi.updateSettingByUserId('', parmas).then(res => {
          if (res) {
            state.showList = false;
            state.itemNameCode = item.itemNameCode;
            state.itemVal = item.itemNameEn;
            message.success('修改频次成功');
            state.loading = false;
          }
        });
      },
    };
  },
};
</script>

<style lang="less" scoped>
.draggable {
  border: 1px dashed #5979f8;
  //min-width: calc(100% - 32px);
  //overflow: auto;
}

.iss-main {
  padding-top: 40px;
  padding-left: 32px;
  padding-right: 32px;
}
.title {
  color: #333333;
  font-size: 24px;
}
.titleTwo {
  color: #6b7592;
  font-size: 12px;
}
.relation {
  margin-top: 16px;
  width: 200px;
}

.draggableContent {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  min-width: calc(100% - 10px);
  overflow: auto;
}

// 拖拽
.eleText {
  border: 1px solid #eeeff5;
  background: #ffffff;
  padding: 9px 0px 9px 16px;
  margin-bottom: 12px;
  border-radius: 4px;
  width: 208px;
  margin-right: 8px;
}

.arrayListNever1,
.arrayListNot2,
.arrayListWeek3,
.arrayListMonth4,
.arrayListQuarterly5,
.arrayListSemiyearly6,
.arrayListYear7 {
  position: relative;
}

.eleContentNone {
  text-align: center;
  color: #97a5ce;
  position: absolute;
  top: 100px;
  left: 20px;
}
.itemAll {
  //display: flex;
  //justify-content: flex-start;
  //align-items: flex-start;
  width: 208px;
  margin-right: 8px;
  border: 1px solid #eeeff5;
  background: rgba(255, 123, 0, 0.02);
  height: 508px;
  overflow: auto;
  border-radius: 4px;
  margin-bottom: 10px;
  padding-right: 4px;

  .ele {
    width: 100%;

    .content {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      border-radius: 4px 4px 0px 0px;
      background: #ffffff;
      padding: 4px 0px 4px 6px;
      border-bottom: 1px solid #eeeff5;
      .header {
      }

      .name {
        font-size: 14px;
        margin-left: 8px;
        width: 150px;
        word-wrap: break-word;
      }
    }
  }
}

.headerText {
  width: 32px;
  height: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.headerUrl {
  width: 32px;
  height: 32px;
}
.showBorder {
  border: 1px dashed #ff8f1f;
  //&::after {
  //  content: '';
  //  position: absolute;
  //  top: 0;
  //  width: 100%;
  //  height: 100%;
  //  z-index: 9;
  //}
}
.pinci {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.dropDown {
  border-radius: 4px;
  opacity: 1;
  background: #ffffff;
  margin-left: 24px;
  position: relative;
  margin-bottom: 10px;
  .itemVal {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    cursor: pointer;
    .iconsImg {
      width: 16px;
      height: 16px;
      margin-right: 6px;
    }
  }
  .dropDownList {
    position: absolute;
    left: 0px;
    top: 36px;
    z-index: 2;
    background: #ffffff;
    width: 200px;
    border: 0.5px solid #eeeeee;
    border-radius: 4px;
    cursor: pointer;

    .dropDownItem {
      border-bottom: 1px solid #eeeeee;
      padding: 12px;
    }
  }
}
// 去掉一个拖动的目标占位符
.ghost {
  display: none;
}
</style>
