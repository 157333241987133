'use strict';

import http from '@/utils/http';
export default {
  // 批量添加到关系组中
  queryGroups(code, data) {
    return http.post(
      '/connections_treasure_box/contact/contact/queryGroups',
      data,
      { code }
    );
  },
  // 根据用户id查询 关系组父级+全部联系人
  groupsId(code, id) {
    return http.get(
      `/connections_treasure_box/contact/groups/getContactParentGroup/${id}`,
      { code }
    );
  },
  // 根据用户主键修改通知设置
  updateSettingByUserId(code, data) {
    return http.put(
      '/connections_treasure_box/user/user-setting/updateSettingByUserId',
      data,
      { code }
    );
  },

  getUserNotifySettingList(code, id) {
    return http.get(
      `/connections_treasure_box/user/user-setting/getUserNotifySettingList/${id}`,
      { code }
    );
  },
};
